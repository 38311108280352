import React from "react";
import styles from "./style.module.css";
import { Pack } from "./Pack/Pack";
import packsData from "./content.json";

export const Packs = () => {
  const packs = packsData.data;

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.upperLabel}>
          <h2>MES ACCOMPAGNEMENTS</h2>
          <h3>MES PACKS</h3>
        </div>
        <img
          src="/assets/pictures/accessoires-soins-pour-bebe-plat.jpg"
          alt="accessoires-soins-pour-bebe-plat.jpg"
          className={styles.upperImg}
        />
      </div>

      {packs.map((pack, idx) => (
        <Pack pack={pack} key={idx} showPrices={false} />
      ))}
    </div>
  );
};
