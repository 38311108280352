import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import Dropdown from "../common/Dropdown/Dropdown";
import IconButton from "@mui/material/IconButton";
import { CiMenuBurger } from "react-icons/ci";

export const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 799);

  const sections = [
    { name: "ACCUEIL", to: "/home" },
    { name: "QUI SUIS-JE ?", to: "/about" },
    {
      name: "MES ACCOMPAGNEMENTS",
      to: "/accompaniments",
      subSections: [
        { name: "MES PRESTATIONS", to: "/accompaniments/prestations" },
        { name: "MES PACKS", to: "/accompaniments/packs" },
      ],
    },
    { name: "CONTACT", to: "/contact" },
  ];

  const handleResize = () => {
    setIsMobile(window.innerWidth < 799);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className={`${styles.menu} ${isMenuOpen ? styles.mobileMenuOpen : ""}`}
    >
      {isMobile && (
        <div className={styles.mobileMenuHeader}>
          {isMobile && (
            <div
              className={`${styles.section} ${styles.activeSection} ${styles.mobileTitle}`}
            >
              CORALIE BABY PLANNER
            </div>
          )}
          <IconButton onClick={toggleMenu}>
            <CiMenuBurger color={isMenuOpen ? "white" : "#ffffff80"} />
          </IconButton>
        </div>
      )}
      {(isMenuOpen || !isMobile) && (
        <div className={styles.menuItems}>
          {sections.map((section, index) => {
            if (section.subSections) {
              return (
                <Dropdown
                  key={index}
                  section={section}
                  isMobile={isMobile}
                  setIsMenuOpen={setIsMenuOpen}
                />
              );
            }
            return (
              <NavLink
                className={({ isActive }) =>
                  `${styles.section} ${isActive ? styles.activeSection : ""}`
                }
                key={index}
                to={section.to}
                onClick={() => isMobile && setIsMenuOpen(false)}
              >
                {section.name}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};
