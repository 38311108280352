import React from "react";
import styles from "./style.module.css";

interface PackProps {
  pack: {
    title: string;
    description: string;
    content: {
      title?: string;
      detailTitle?: string;
      description: string;
    }[];
    advantage: string;
    price: string;
    realPrice: string;
  };
  showPrices: boolean;
}

export const Pack = (props: PackProps) => {
  const { pack, showPrices } = props;
  const { title, description, content, advantage, price, realPrice } = pack;

  return (
    <div className={styles.packContainer}>
      <h2>{title}</h2>
      <hr className={styles.separator} />
      <p>{description}</p>
      <div className={styles.packBox}>
        {content.map((content, idx) => (
          <div key={idx}>
            {content.title && <h3>{content.title}</h3>}
            {content.detailTitle && <h5>{content.detailTitle}</h5>}
            <p>{content.description}</p>
          </div>
        ))}
        <p>
          <strong>Avantages du pack : </strong>
          {advantage}
        </p>
        {showPrices && (
          <>
            <hr className={styles.separator} />
            <div className={styles.price}>
              <strong>{price}</strong> ({realPrice})
            </div>
          </>
        )}
      </div>
    </div>
  );
};
