import React from "react";
import styles from "./style.module.css";

interface ButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export const CocoButton = (props: ButtonProps) => {
  const { title, onClick, disabled } = props;

  return (
    <button className={styles.cocoButton} onClick={onClick} disabled={disabled}>
      {title}
    </button>
  );
};
