import React from "react";
import styles from "./style.module.css";

export const About = () => {
  return (
    <div className={styles.content}>
      <div className={styles.upperSection}>
        <div>
          <h3>QUI SUIS-JE ?</h3>
          <p>
            Je m’appelle Coralie, j’ai vingt sept ans et je suis maman de deux
            merveilleuses petites filles de quatre ans et huit mois. Je
            travaille initialement dans les assurances. J’ai toujours été
            passionnée par l’univers de la grossesse, des bébés et puis des
            enfants.
          </p>
        </div>
        <img alt="coralie" src="/assets/pictures/coco.png" />
      </div>
      <div className={styles.middleSection}>
        <h2>POURQUOI</h2>
        <h3>BABY PLANNER ?</h3>
        <p>
          Je suis tombée enceinte de ma première fille, et sont arrivées presque
          instantanément un milliard de questions. Est-ce que je serai une bonne
          mère ? Quelle poussette acheter ? Et le siège auto ? Comment je dois
          faire pour qu’elle dorme ? Je me suis sentie perdue, et surtout je
          n’ai pas compris pourquoi pendant la grossesse le corps médical était
          très présent et après la naissance nous devions autant nous
          débrouiller. Trois ans plus tard, je suis tombée enceinte de ma
          seconde fille. Les choses ont été très différentes, je les ai vues
          sous un autre angle et avec un peu plus de recul grâce à ma première
          expérience. J‘ai également continué de me documenter entre temps, ce
          qui m‘a permis d‘appréhender les choses plus sainement. En faisant ce
          constat, je me suis dit qu’il fallait aider les futurs et jeunes
          parents en leur apportant un soutien, des informations, des réponses à
          leurs questions et un accompagnement, et c’était parti !
        </p>
        <p>
          J’ai découvert le métier de baby planner qui est très répandu dans
          d’autres pays mais encore très peu chez nous, j’ai contacté le seul
          centre de formation en France et je me suis lancée !
        </p>
        <p>
          Aujourd’hui, j’exploite un peu plus chaque jour ce métier qui fait de
          moi ce que je suis et qui est rempli de convictions qui sont les
          miennes : accompagner les autres là ou nous ne le sommes pas! Avant
          tout un métier d’amour plutôt que de raison, j’ai à coeur de le
          développer au maximum.
        </p>
      </div>
      <div className={styles.bottomSection}>
        <h2>CE QUI ME TIENT A COEUR</h2>
        <p>
          Je pense que la famille est vraiment le plus important, et que
          parfois, nous n’avons pas besoin de beaucoup de choses pour au final
          voir la valeur des moments même dans les plus simples ou les plus
          compliqués.
        </p>
        <p>
          En faisant connaître ce métier autour de moi, en me développant, je
          souhaite que les futurs et jeunes parents aient de vraies réponses et
          surtout qu’ils ne se sentent pas seuls !
        </p>
        <p>
          C’est un véritable défi pour cette profession si peu connue et
          pourtant essentielle, mais c’est aussi en la développant et en
          validant ce défi que les choses évolueront sur la parentalité.
        </p>
      </div>
      <img
        alt="macaron"
        src="/assets/pictures/macaron_parent_factory.png"
        className={styles.macaron}
      />
    </div>
  );
};
