import React from "react";
import styles from "./style.module.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Menu } from "../Menu/Menu";

export const Header = () => {
  return (
    <>
      <div className={styles.titleContent}>
        <div className={styles.sides}>
          <img
            alt="logo"
            src="/assets/pictures/macaron_parent_factory.png"
            className={styles.macaron}
          />
        </div>
        <img
          alt="logo"
          src="/assets/pictures/logo-removebg.png"
          className={styles.logo}
        />
        <div className={styles.sides}>
          <FaFacebook
            className={styles.icon}
            size={30}
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=61550560948107"
              )
            }
          />
          <FaInstagram
            className={styles.icon}
            size={30}
            onClick={() =>
              window.open("https://www.instagram.com/coralie_baby_planner/")
            }
          />
        </div>
      </div>
      <Menu />
    </>
  );
};
