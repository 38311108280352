import React, { useState } from "react";
import styles from "./style.module.css";
import { CocoButton } from "../../../common/CocoButton/CocoButton";

interface Prestation {
  title: string;
  description: string;
  formulas: {
    title: string;
    content: string[];
    details: string;
    price: string;
  }[];
}

interface PrestationProps {
  prestation: Prestation;
  showPrices: boolean;
}

export const Prestation = (props: PrestationProps) => {
  const { prestation, showPrices } = props;

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <div className={styles.prestationContainer}>
      <h2>{prestation.title}</h2>
      <hr className={styles.separator} />
      <p>{prestation.description}</p>
      <div className={styles.prestationDetails}>
        {prestation.formulas.map((formula) => (
          <div className={styles.prestationBox} key={formula.title}>
            <h5>{formula.title}</h5>
            {openDetails ? (
              <>
                <p>{formula.details}</p>
                {showPrices && (
                  <>
                    <hr className={styles.separator} />
                    <h5>{formula.price}</h5>
                  </>
                )}
              </>
            ) : (
              <ul>
                {formula.content.map((content, idx) => (
                  <>
                    {!formula.content.at(idx - 1) && (
                      <hr className={styles.separator} />
                    )}
                    <li key={content}>
                      <p>{content}</p>
                    </li>
                    {!!formula.content.at(idx + 1) && (
                      <hr className={styles.separator} />
                    )}
                  </>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <CocoButton
        title={openDetails ? "Moins de détails" : "Plus de détails"}
        onClick={() => setOpenDetails(!openDetails)}
      />
    </div>
  );
};
