import * as React from "react";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { Header } from "./components/Header/Header";
import { Home } from "./components/Pages/Home/Home";
import { Footer } from "./components/Footer/Footer";
import { About } from "./components/Pages/About/About";
import { Contact } from "./components/Pages/Contact/Contact";
import { LegalNotice } from "./components/Pages/LegalNotice/LegalNotice";
import { DataProtection } from "./components/Pages/DataProtection/DataProtection";
import { Prestations } from "./components/Pages/Prestations/Prestations";
import { Packs } from "./components/Pages/Packs/Packs";
import "./variables.css";

export const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path={`/home`} element={<Home />} />
          <Route path={`/about`} element={<About />} />
          <Route path={`/contact`} element={<Contact />} />
          <Route
            path={`/accompaniments/prestations`}
            element={<Prestations />}
          />
          <Route path={`/accompaniments/packs`} element={<Packs />} />
          <Route path={`/legal-notice`} element={<LegalNotice />} />
          <Route path={`/data-protection`} element={<DataProtection />} />
        </Routes>
        <Footer />
      </Router>
    </StyledEngineProvider>
  );
};

export default App;
