import React, { useState } from "react";
import styles from "./style.module.css";
import { Snackbar, SnackbarCloseReason } from "@mui/material";
import emailjs from "@emailjs/browser";
import { CocoButton } from "../../common/CocoButton/CocoButton";

export const Contact = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const defaultFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const formatMail = () => {
    return `Nom : ${formData.lastName}\n\nPrénom : ${formData.firstName}\n\nMail : ${formData.email}\n\nN° de téléphone : ${formData.phoneNumber}\n\nMessage :\n${formData.message}`;
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setOpen(true);

    emailjs
      .send(
        "service_vw336fj",
        "template_2j5gd9k",
        { message: formatMail() },
        "kT7Llnw3ikOdd-6l3"
      )
      .catch((err) => {
        setError(err);
      });

    setFormData(defaultFormData);
  };

  return (
    <div>
      <div className={styles.container}>
        <h2>UNE QUESTION ?</h2>
        <h3>CONTACTEZ-MOI !</h3>

        <div className={styles.formSection}>
          <label htmlFor="firstName">Prénom *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Prénom"
            required
          />
        </div>

        <div className={styles.formSection}>
          <label htmlFor="lastName">Nom *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Nom"
            required
          />
        </div>

        <div className={styles.formSection}>
          <label htmlFor="email">EMAIL *</label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>

        <div className={styles.formSection}>
          <label htmlFor="email">N° DE TELEPHONE *</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="N° de téléphone"
            required
          />
        </div>

        <div className={styles.formSection}>
          <label htmlFor="message">MESSAGE *</label>
          <textarea
            id="message"
            name="message"
            rows={5}
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            required
          />
        </div>

        <CocoButton
          title={"Envoyer"}
          onClick={handleSubmit}
          disabled={Object.values(formData).some((v) => !v.length)}
        />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Le message a bien été envoyé !"
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Une erreur est survenue lors de l'envoi du message"
      />
    </div>
  );
};
