import React from "react";
import styles from "./style.module.css";
import prestationsData from "./content.json";
import { Prestation } from "./Prestation/Prestation";

export const Prestations = () => {
  const prestations = prestationsData.data;

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.upperLabel}>
          <h2>MES ACCOMPAGNEMENTS</h2>
          <h3>MES PRESTATIONS</h3>
        </div>
        <img
          src="/assets/pictures/vue-dessus-mains-tenant-jouet-pour-bebe.jpg"
          alt="vue-dessus-mains-tenant-jouet-pour-bebe.jpg"
          className={styles.upperImg}
        />
      </div>

      {prestations.map((prestation, idx) => (
        <Prestation prestation={prestation} key={idx} showPrices={false} />
      ))}
    </div>
  );
};
