import React from "react";
import styles from "./style.module.css";

export const Home = () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <img
        src="/assets/pictures/accessoires-soins-pour-bebe-plat.jpg"
        alt="accessoires-soins-pour-bebe-plat.jpg"
        className={styles.topImg}
      />
      <div className={styles.topText}>
        <h2>FUTURS OU JEUNES PARENTS ?</h2>
        <h3>QU‘EST-CE QU‘UN BABY PLANNER ?</h3>
        <p>
          « Un baby planner, est un consultant privé en maternité. Il rencontre
          les futurs et les nouveaux parents et offre de l’éducation, des
          ressources et du soutien. Il peut également recommander des produits,
          des ressources, des services et un soutien ».
        </p>
        <h4>ET CONCRETEMENT ?</h4>
        <p>
          Un baby planner, c’est quelqu’un qui va t’accompagner dans cette
          nouvelle aventure de la parentalité ! A partir du moment ou ce petit
          être grandit dans ton ventre, jusqu’à ses premières années de vie. Tu
          pourras profiter de ses services pour te renseigner, t’informer, en
          apprendre plus et pour appréhender cette nouvelle vie ou les nouvelles
          étapes qui t’attendent.
        </p>
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.middleText}>
        <h2>UN BABY PLANNER</h2>
        <h3>EST-CE QUE J‘EN AI BESOIN ?</h3>
      </div>
      <img
        src="/assets/pictures/futur-pere-perplexe-se-gratte-tete-car-ne-sait-pas-quels-vetements-acheter-pour-enfant-naitre-femme-enceinte-ravie-touche-ventre-tient-mobile-bebe-exprime-emotions-positives-deviens-bientot-parents.jpg"
        alt="futur-pere-perplexe-se-gratte-tete-car-ne-sait-pas-quels-vetements-acheter-pour-enfant-naitre-femme-enceinte-ravie-touche-ventre-tient-mobile-bebe-exprime-emotions-positives-deviens-bientot-parents.jpg"
        className={styles.bottomImg}
      />
      <div className={styles.bottomText}>
        <p>
          Tu es excitée à l’idée de cette nouvelle vie ? Tu appréhendes les
          changements ? Tu veux tout savoir ? Tu as besoin de te renseigner ? Tu
          aimerais bénéficier d’expertises quand à certains sujets ? Tu veux
          commencer les achats pour bébé mais tu ne sais pas par ou commencer ni
          ce qu’il faut vraiment? Tu aimerais t’informer sur les éléments
          dangereux ? En savoir plus sur le sommeil de bébé ? Concrètement tu
          voudrais être accompagnée ? Tu peux faire appel à un baby planner pour
          ça!
        </p>
      </div>
    </div>
  </div>
);
