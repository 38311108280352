import React, { Dispatch, SetStateAction, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./style.module.css";
import { RiArrowDropDownFill } from "react-icons/ri";
import { RiArrowDropUpFill } from "react-icons/ri";

interface DropdownProps {
  section: {
    name: string;
    to: string;
    subSections?: {
      name: string;
      to: string;
    }[];
  };
  isMobile: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export const Dropdown = (props: DropdownProps) => {
  const { section, isMobile, setIsMenuOpen } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    isMobile && setIsMenuOpen(false);
  };

  const location = useLocation();
  const isDropdownActive = location.pathname.includes(section.to);

  return (
    <>
      <div
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={`${styles.name} ${
          isDropdownActive ? styles.activeName : ""
        }`}
      >
        {section.name}
        {open ? <RiArrowDropUpFill /> : <RiArrowDropDownFill />}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "var(--primary-color)",
            color: "var(--font-light-color)",
          },
        }}
      >
        {section.subSections?.map((subSection, index) => (
          <MenuItem key={index} onClick={handleClose}>
            <NavLink
              className={({ isActive }) =>
                `${styles.navLink} ${isActive ? styles.activeNavLink : ""}`
              }
              to={subSection.to}
            >
              {subSection.name}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Dropdown;
